.model-page {
    margin: 20px;
}

.model-description-area {
    display: flex;
    flex-direction: row;
    gap: 15px;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    margin: 30px 0;
}

@media (max-width: 900px) {
    .model-description-area {
        flex-direction: column;
    }
}

.model-description-area>img {
    /* flex: 0 0 auto; */
    max-width: 50%;
    height: auto;
    margin-bottom: auto;
    aspect-ratio: initial !important;
}

@media (max-width: 900px) {
    .model-description-area>img {
        max-width: 80%;
        margin: auto;
    }
}

@media (max-width: 600px) {
    .model-description-area>img {
        max-width: 100%;
    }
}

.demo-container {
    margin: 30px 0 0 0;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

@media (min-width: 1000px) {
    .demo-container {

        flex-direction: row;
    }
}

.demo-section {
    flex: 1;
    background-color: var(--dark-2);
    padding: 20px;
    border: solid 1px var(--dark-4);
    border-radius: 5px;
}

.input-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.input-field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.label {
    font-size: 17px;
    font-weight: 600;
    flex: 0 0 auto;
}

.text-input,
.text-area-input {
    background-color: var(--dark-5) !important;
    border-color: var(--dark-4) !important;
    color: var(--light) !important;
    flex: 1 1 auto;
    min-width: 70%;
}

.slider-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.slider {
    flex: 100;
}

.slider-text-input {
    background-color: var(--dark-5) !important;
    border-color: var(--dark-4) !important;
    color: var(--light) !important;
    margin-left: 5px;
    flex: 1;
    width: 80px;
}



.image-preview {
    margin-top: 5px;
}

.spinner {
    width: 55px;
    height: 55px;
    border: 6px solid var(--dark-5);
    border-top: 6px solid var(--light);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 5.5px auto;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sm-text {
    font-size: 15px;
    /* text-align: center; */
    margin-top: 20px;
}

.split-container {
    display: flex;
}

.split-left,
.split-right {
    padding: 20px;
    border: 2px solid var(--gray);
}

.split-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right-width: 1px;
}

.split-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left-width: 1px;
}

.grid-container {
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    margin-right: auto;
    background-color: var(--dark-2);
    border: 1px solid white;
    border-radius: 10px;
}

.grid-item {
    padding: 15px;
    text-align: center;
    color: var(--light);
    font-size: 18px;
}

.grid-item:nth-child(odd) {
    border-right: 1px solid var(--light);
}

.grid-item:nth-child(-n+2) {
    font-weight: 600;
}

.grid-item:not(:nth-last-child(-n+2)) {
    border-bottom: 1px solid var(--light);
}