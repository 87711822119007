h1,
h2,
h5 {
    font-weight: 600;
    color: var(--light);
}

p,
h3,
li {
    color: var(--gray);
}

p,
li {
    font-size: 20px;

}

@media (max-width: 768px) {

    p,
    li {
        font-size: 18px;
    }
}

h1 {
    font-size: 50px;
}

hr {
    color: var(--gray);
    margin: 50px 0;
}

footer {
    padding: 20px 40px;
    border-top: solid 1px var(--dark-3);
    background-color: var(--dark-2);
    text-align: center;
    bottom: 0;

}

footer > * {
    display: inline;
    color: var(--light) !important;
    margin: 0 20px 0 0;
}

.footer-text {
    font-size: 15px;
}

.page-margins {
    margin: 50px 7%;

}

@media (max-width: 768px) {
    .page-margins {
        margin: 25px;
    }
}

.btn-primary {
    background-color: var(--dark-2);
    margin: 10px;
    padding: 10px 20px;
    border-color: var(--dark-3);
    font-weight: 600;
}

.btn-primary:hover {
    background-color: var(--dark-3);
    border-color: var(--dark-4);
}

.btn-primary:active {
    background-color: var(--dark-4) !important;
    border-color: var(--dark-4) !important;
}

.light-button {
    background-color: var(--dark-3);
    border-color: var(--dark-4);
}

.light-button:hover {
    background-color: var(--dark-4);
    border-color: var(--gray);
}

.light-button:active {
    background-color: var(--gray) !important;
    color: var(--dark-3) !important;
}

.light-button:disabled {
    background-color: var(--dark-2);
    border-color: var(--dark-2);
}

.about-skills-split {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    gap: 100px;


}

@media (max-width: 900px) {
    .about-skills-split {
        flex-direction: column;
        gap: 40px;
    }
}

.about-section {
    flex: 2;
    max-width: 650px;
}

.skills-section {
    flex: 1;
    max-width: 500px;
}

.about-cards {
    list-style: none;
    padding-left: 30px;
    position: relative;
    /* border-left: solid 2px var(--dark-3); */
}

.about-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    color: var(--light);
    margin: 8px 0;
    padding: 10px 20px;
    font-size: 17px;
    background-color: var(--dark-2);
    border-radius: 5px;
    border: solid 1px var(--dark-3);

}

@media (max-width: 768px) {
    .about-card {
        font-size: 17px;
        padding: 7px 14px;
        margin: 7px 0;
        flex-direction: column;
        gap: 0px;
    }
}

.about-card-popup {

    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 400px;
    max-width: 100%;
    /* max-width: 400px; */
    /* transform: translateX(-50%); */
    background-color: var(--dark-3);
    padding: 20px;
    border: 1px solid var(--dark-4);
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.about-card-popup::after,
.about-card-popup::before {
    right: 100%;
    top: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.about-card-popup:after {
    border-width: 13px;
    margin-top: -13px;
    border-right-color: var(--dark-3);
    transform: translateX(1.5px);
}

.about-card-popup:before {
    border-width: 13px;
    margin-top: -13px;
    border-right-color: var(--dark-4);
}

.about-card:hover .about-card-popup {
    display: inline-block;
}

@media (max-width: 900px) {
    .about-card-popup {
        top: 100%;
        left: 0;
        bottom: auto;
        right: auto;
    }

    .about-card-popup::before,
    .about-card-popup::after {
        border-color: transparent;
        bottom: 100%;
        left: 20px;
        right: auto;
        top: auto;
    }

    .about-card-popup:after {
        border-bottom-color: var(--dark-3);
        border-width: 13px;
        margin-left: -13px;
        transform: translateY(2px);
    }

    .about-card-popup:before {
        border-bottom-color: var(--dark-4);
        border-width: 13px;
        margin-left: -13px;
    }
}

.no-wrap {
    white-space: nowrap;
}

.about-card:hover {
    transition: 200ms;
    background-color: var(--dark-3);
}

.about-card:hover .timeline-circle {
    transition: 200ms;
    background-color: var(--gray);
}

.about-card:hover .timeline-line {
    transition: 200ms;
    background-image: linear-gradient(var(--gray), var(--dark-4));
}

.timeline-line {
    position: absolute;
    top: 20%;
    bottom: -40%;
    left: -25px;
    width: 4px !important;
    background-color: var(--dark-3);
    display: flex;
    justify-content: center;
}

.about-card:last-child>.timeline-line {
    bottom: -10%;
}

.timeline-circle {
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: var(--dark-3);
    border-radius: 50%;
    border: solid 3px var(--dark-4);
}

.skills-div {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

}

@media (max-width: 768px) {
    .skill-div {
        gap: 7px;
    }
}

.skill-card {
    color: var(--light);
    padding: 8px 16px;
    background-color: var(--dark-2);
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    border: solid 1px var(--dark-3);

}

@media (max-width: 768px) {
    .skill-card {
        font-size: 17px;
        padding: 7px 14px;
    }
}

.model-card {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    padding: 20px;
    background: var(--dark-2);
    border-radius: 5px;
    border: solid 1px var(--dark-4);

}

@media (max-width: 768px) {
    .model-card {
        margin: 7px 5px;
    }
}

.model-card:hover {
    background-color: var(--dark-3);
    transition: transform 200ms;
    transform: scale(1.005);
    cursor: pointer;
}

.model-description {
    margin: 0;
}

.model-card-image {
    width: 500px;
    max-width: 100%;
    max-height: 100%;
    margin-top: 10px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
}