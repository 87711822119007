/* Import custom font */
@font-face {
  font-family: "SourceSans";
  src: local("SourceSans"),
    url("../media/fonts/Source_Sans_3/static/SourceSans3-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "SourceSans";
  src: local("SourceSans"),
    url("../media/fonts/Source_Sans_3/static/SourceSans3-SemiBold.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Titillium";
  src: local("Titillium"),
    url("../media/fonts/Titillium_Web/TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Titillium";
  src: local("Titillium"),
    url("../media/fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Titillium";
  src: local("Titillium"),
    url("../media/fonts/Titillium_Web/TitilliumWeb-Bold.ttf") format("truetype");
  font-weight: 700;
}



/* General styles */
:root {
  --dark-1: rgb(14, 14, 14);
  --dark-2: rgb(37, 37, 37);
  --dark-3: rgb(47, 47, 47);
  --dark-4: rgb(71, 71, 71);
  --dark-5: rgb(20, 20, 20);
  --light: rgb(255, 249, 241);
  --gray: rgb(184, 179, 173);
  --orange: rgb(255, 220, 174);
  --red: rgb(239, 124, 101);

}

* {
  font-family: 'Titillium', sans-serif;
}

.light {
  color: var(--light);
}

.gray {
  color: var(--gray);
}

.red {
  color: var(--red);
}

html, body, #root, .app-contents {
  height: 100%;
}


.app-contents {
  display: flex;
  flex-direction: column;
  padding-top: 71px;
}

html {
  scroll-behavior: auto !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-1) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar styles */
.navbar {
  padding: 0 3.5vw !important;
  background-color: var(--dark-2);
  border-bottom: solid var(--dark-4) 1px;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 71px;
}

.navbar-brand,
.nav-link,
.dropdown-item {
  font-family: 'SourceSans', sans-serif;
  color: var(--light) !important;
}

.navbar-brand {
  font-size: 17px !important;
  padding: 10px 10px !important;
  font-weight: 500 !important;

}

.nav-link {
  font-size: 16px !important;
  padding: 5px 15px !important;
  border: solid var(--dark-4) 1px !important;
  border-radius: 6px !important;
  font-weight: 400 !important;

}

.nav-link:hover,
.navbar-brand:hover {
  background-color: var(--dark-3);
}

@media (max-width: 500px) {
  .navbar-brand {
    font-size: 16px !important;
    padding: 6px 6px !important;
  }

  .nav-link {
    font-size: 16px !important;
    padding: 3px 9px !important;
  }
}

.dropdown {
  margin: 0 auto 0 0 !important;
}

.dropdown-toggle::after {
  margin-left: 0.5em !important;
  vertical-align: 0.15em !important;
}

.dropdown-menu {
  background-color: var(--dark-2) !important;
  border: solid var(--dark-4) 1px !important;
}

.dropdown-item {
  color: var(--light) !important;
  text-decoration: none;
  font-weight: 400 !important;
}

.dropdown-item:hover {
  background-color: var(--dark-3) !important;
}